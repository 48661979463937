/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAvatar from "components/MKAvatar";
import { useState,useContext } from "react";
import { Context } from "../../../../Context";

function DefaultBlogCard({ image, category, title, description, author, raised, action,short }) {
  const [isShort, setIsShort] = useState(true);
  const {langContext, setLangContext} = useContext(Context);
  const imageTemplate = (
    <>
      <MKBox
        component="img"
        src={image}
        alt={title}
        maxHeight="150px"
        maxWidth="300px"
        position="relative"
        zIndex={1}
      />
    </>
  );

  return (
    <Card>
      <MKBox position="relative" borderRadius="lg" mx={2} style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent: "center",height:"150px"}}>
        {action.type === "internal" ? (
          <Link to={action.route}>{imageTemplate}</Link>
        ) : (
          <MuiLink href={action.route} target="_blank" rel="noreferrer">
            {imageTemplate}
          </MuiLink>
        )}
      </MKBox>
      <MKBox p={3}>
        {action.type === "internal" ? (
          <Link to={action.route}>
            <MKTypography
              variant="h5"
              textTransform="capitalize"
              my={1}
              sx={{ display: "inline-block" }}
            >
              {title}
            </MKTypography>
          </Link>
        ) : (
          <MuiLink href={action.route} target="_blank" rel="noreferrer">
            <MKTypography
              variant="h5"
              textTransform="capitalize"
              mt={2}
              mb={1}
              sx={{ display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1, }}
              
            >
              {title}
            </MKTypography>
          </MuiLink>
        )}
        {isShort ? (
        <MKTypography variant="body2" color="text" mb={0}  style={{textAlign: "justify"}}>
          {short}
        </MKTypography> )
        : (
        <MKTypography variant="body2" color="text" mb={0} style={{textAlign: "justify"}}>
          {description}
        </MKTypography> )
          }
          {isShort ? (
        <MKTypography variant="body2" color="text" mb={0} py={0}>
          <span className="bold" onClick={() => setIsShort(false)}>{langContext == "Français" ? "Plus" : "More"}</span>
        </MKTypography>
          ) : (
        <MKTypography variant="body2" color="text" mb={2}>
          <span className="bold" onClick={() => setIsShort(true)}>{langContext == "Français" ? "Moins" : "Less"}</span>
        </MKTypography>
          )}

      </MKBox>
    </Card>
  );
}

// Setting default props for the DefaultBlogCard
DefaultBlogCard.defaultProps = {
  category: false,
  author: false,
  raised: true,
};

// Typechecking props for the DefaultBlogCard
DefaultBlogCard.propTypes = {
  image: PropTypes.string.isRequired,
  category: PropTypes.oneOfType([
    PropTypes.shape({
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
      ]).isRequired,
      label: PropTypes.string.isRequired,
    }),
    PropTypes.bool,
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  author: PropTypes.oneOfType([
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    }),
    PropTypes.bool,
  ]),
  raised: PropTypes.bool,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]).isRequired,
    route: PropTypes.string.isRequired,
  }).isRequired,
};

export default DefaultBlogCard;
