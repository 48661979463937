// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Context } from "../../../Context";
import { useEffect, useRef, useState,useContext } from "react";
import {sendMessage } from "../../../../src/hooks/airtable";
function ContactUsOne() {
  const {langContext, setLangContext} = useContext(Context);
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const checkForm = () => {
    if (nom === "" || email === "" || message === "") {
      return false;
    }else{
      return true;
    }
  };
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={10}
          lg={7}
          mx="auto"
          mb={{ xs: 0, md: 6 }}
          textAlign="center"
        >
          <MKTypography variant="h3" mb={1}>
            <span className="font-title-black">
            {langContext === "Français" ? "Nous contacter" : "Contact us"}
            </span>
          </MKTypography>
          <MKTypography variant="body2" color="text">
            {langContext === "Français" ? "Pour toute autre question, y compris les opportunités de partenariat, veuillez contacter en utilisant notre formulaire de contact." : "For further questions, including partnership opportunities, please contact using our contact form."}
          </MKTypography>
        </Grid>
        <Grid container item xs={12} lg={8} sx={{ mx: "auto" }}>
          <MKBox width="100%" component="form" method="post" autocomplete="off">
            <MKBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput label={langContext === "Français" ? "Nom et Prénom" : "Full Name"} fullWidth onChange={(e)=>{setNom(e.target.value)}}/>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput type="email" label="Email" fullWidth onChange={(e)=>{setEmail(e.target.value)}}/>
                </Grid>
                <Grid item xs={12}>
                  <MKInput label={langContext === "Français" ? "En quoi pouvons nous vous aider ?" : "How can we help you?"} multiline onChange={(e)=>{setMessage(e.target.value)}} fullWidth rows={6} />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" xs={12} my={6}>
                <MKButton 
                  variant="gradient" 
                  color="info" 
                  onClick={()=>{
                    if(checkForm()){
                      sendMessage(nom,email,message);
                      alert("Votre message a bien été envoyé");
                      setEmail("");
                      setNom("");
                      setMessage("");
                    }
                    else{
                      alert("Veuillez remplir tous les champs");
                    }
                  }}>
                {langContext === "Français" ? "Envoyer" : "Send"}
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ContactUsOne;