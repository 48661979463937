/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useEffect, useRef, useState,useContext } from "react";
import {getArticles, getPages } from "../../../../../src/hooks/airtable";
import { Context } from "../../../../Context";

function BuiltByDevelopers() {
  const bgImage =
    "https://res.cloudinary.com/dbt1oxzwd/image/upload/v1686999300/julia-koblitz-RlOAwXt2fEA-unsplash_t95eso.jpg";
    const {langContext, setLangContext} = useContext(Context);
    const [contentData, setContentData] = useState([]);
    const [isFetch, setIsFetch] = useState(false);
  
    useEffect(() => {
      async function fetchData() {
        const data = await getPages();
        setContentData(data);
        setIsFetch(true)
      /*   console.log(data)
        console.log(contentData.find(item => item.fields.key === "Section 1 " + langContext).fields.Titre); */
      }
      fetchData();
  
    }, []);
  return (
    <>
    {isFetch && (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <div style={{display:"flex",flexDirection:"column"}}>
          <MKTypography variant="h4" color="white" fontWeight="bold">
            <span className="font-subtitle-white">{langContext === "Français" ? "En quelques mots" : "In a few words"}</span>
          </MKTypography>
          <MKTypography variant="h1" color="white" mb={1}>
            <span className="font-title-white">{contentData.find(item => item.fields.key === "En quelques mots " + langContext && item.fields.Page === "Accueil").fields.Titre}</span>
          </MKTypography>
          </div>
          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
          {contentData.find(item => item.fields.key === "En quelques mots " + langContext && item.fields.Page === "Accueil").fields.Texte}
          </MKTypography>
          <MKTypography
            component="a"
            href="/projet"
            rel="noreferrer"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          >
            {langContext === "Français" ? "En savoir plus" : "View more"} <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
    )}
    </>
  );
}

export default BuiltByDevelopers;
