/* eslint-disable no-unused-vars */
const AirtablePlus = require("airtable-plus");

//const API_KEY = "patEgsVqOicn5YQ3n.a95deb62e1dc3541540c33aa03adb9ea023fbcbf37c5a0237925159c2e79c94a";
const API_KEY = 'patrPZhlfhIcm7246.9696df580635ea59b5b1b620999abfccc040974af8febb4df72e94aae1f42efe'
//const BASE = "appldhEyfmVfqXfH1";
const BASE = 'appsAx7dsToHRWeGc'

const airtableArticles = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: "Articles",
});
const airtablePages = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: "Textes Site Web",
});
const airtableContact = new AirtablePlus({
  baseID: BASE,
  apiKey: API_KEY,
  tableName: "Contact",
});

export async function getArticles() {
  /* const filter = `({Space} = '${space}')`;
  const res2 = await airtableArticles.read({ filterByFormula: filter }); */
  const res = await airtableArticles.read();
  return res;
}
export const getPages = async () => {
  const res = await airtablePages.read();
  return res;
};

export async function getPage() {
  const res = await airtablePages.read();
  return res;
}

export async function sendMessage(Nom, Email, Message) {
  const res = await airtableContact.create({
    Nom: Nom,
    Email: Email,
    Message: Message,
  });
  return res;
}