/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";
import { useState,useContext } from "react";

// rellax
import Rellax from "rellax";
import MKButton from "components/MKButton";
import routesEN from "routesEN";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";
import Steps from "pages/Blogs/SingleArticle/sections/Steps";
import OurEfforts from "pages/Blogs/SingleArticle/sections/OurEfforts";
import Features from "pages/Blogs/SingleArticle/sections/Features";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";
import {getArticles, getPages } from "../../../src/hooks/airtable";
import { Context } from "../../Context";
import ContactUsOne from "pages/presentation/sections/Contact";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import "./style.css";

// Images
import bgImage from "assets/images/bg5.jpg";

function SingleArticle() {
  const headerRef = useRef(null);
  const [contentData, setContentData] = useState([]);
  const {langContext, setLangContext} = useContext(Context);
  const [isFetch, setIsFetch] = useState(false);
  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()    

  // Setting up rellax
/*   useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []); */

  useEffect(() => {
    async function fetchData() {
      const data = await getPages();
      setContentData(data);
      //console.log(data)
      //console.log(contentData.find(item => item.fields.key === "Section 1 " + langContext));
      setIsFetch(true);
    }
    fetchData();

  }, []);

  return (
    <>
      <MKBox shadow="sm" py={0.25} sx={{position: "sticky", top: 0, zIndex: 10,backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",}}>
        <DefaultNavbar
          routes={langContext =="Français" ? routes : routesEN}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-pro-react",
            label: "buy now",
            color: "info",
          }}
          brand={"Prevalung"}
            sticky
            transparent
            relative
            center
        />
      </MKBox>
      {isFetch ? (
      <>
      <MKBox
        ref={headerRef}
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687707824/Untitled_3_nbgi44.png"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Impacts").fields.Titre}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
               {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Impacts").fields.Texte}
            </MKTypography>

            {/* <MKButton style={{marginTop:"5px"}} variant="gradient" color="primary" sx={{ width:"200px" }} onClick={()=>{executeScroll()}}>
            {langContext === "Français" ? "Rejoindre" : "Join"}
            </MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* Titre */}
        <MKBox component="section" py={2} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <div style={{display:"flex",flexDirection:"column"}}>
                <MKTypography variant="h3" mb={3}>
                <span className="font-title-black-1">{contentData.find(item => item.fields.key === "Titre Impacts " + langContext && item.fields.Page === "Impacts").fields.Titre}</span>
                </MKTypography>
              </div>
            </Grid>
          </Container>
        </MKBox>
        {/* IMPACT 1 */}
        <MKBox component="section" py={1} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "Impact 1 " + langContext && item.fields.Page === "Impacts").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        {/* IMPACT 2 */}
        <MKBox component="section" py={1} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "Impact 2 " + langContext && item.fields.Page === "Impacts").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        {/* IMPACT 3 */}
        <MKBox component="section" py={1} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "Impact 3 " + langContext && item.fields.Page === "Impacts").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        {/* IMPACT 4 */}
        <MKBox component="section" py={1} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "Impact 4 " + langContext && item.fields.Page === "Impacts").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        {/* IMPACT 5 */}
        <MKBox component="section" py={1} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                  {contentData.find(item => item.fields.key === "Impact 5 " + langContext && item.fields.Page === "Impacts").fields.Texte}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>

        <div ref={myRef}>
          <ContactUsOne/>
        </div>
        {/* <Steps />
        <OurEfforts />
        <Features />
        <Posts />
        <Support /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      </>): null
      }
    </>
  );
}

export default SingleArticle;
