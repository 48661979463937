/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useRef, useState,useContext } from "react";
import { useMediaQuery } from 'react-responsive'

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";

// Presentation page sections
import Counters from "pages/presentation/sections/Counters";
import Information from "pages/presentation/sections/Information";
import DesignBlocks from "pages/presentation/sections/DesignBlocks";
import AuthPages from "pages/presentation/sections/AuthPages";
import Pages from "pages/presentation/sections/Pages";
import Testimonials from "pages/presentation/sections/Testimonials";
import Pricing from "pages/presentation/sections/Pricing";
import ContactUsOne from "pages/presentation/sections/Contact";
// Presentation page components
import BuiltByDevelopers from "pages/presentation/components/BuiltByDevelopers";
import BlogPostTwo from "pages/presentation/sections/Blog";
import LogoAreaThree from "pages/presentation/sections/Logos";
// @mui material components
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import {getArticles, getPages } from "../../../src/hooks/airtable";
// Material Kit 2 PRO React components
import { Context } from "../../Context";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Coworking/components/AboutUsOption";
// Routes
import routes from "routes";
import routesEN from "routesEN";
import footerRoutes from "footer.routes";
import "./style.css";

// Images
import bgImage from "assets/images/bg-presentation.jpg";
import {lang} from "hooks/language"
function Presentation() {
  const [contentData, setContentData] = useState([]);
  const {langContext, setLangContext} = useContext(Context);
  const [isFetch, setIsFetch] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  useEffect(() => {
    async function fetchData() {
      const data = await getPages();
      setContentData(data);
      setIsFetch(true)
    }
    fetchData();

  }, []);
  return (
    <>
    {isFetch ? (
    <>
    {/* BACKGROUND */}
      <div className="background">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
      </div>
    {/* MENU */}
      <MKBox shadow="sm" py={0.25} sx={{position: "sticky", top: 0, zIndex: 10,backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",}}>
        <DefaultNavbar
          routes={langContext =="Français" ? routes : routesEN}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-pro-react",
            label: "buy now",
            color: "info",
          }}
          brand={"Prevalung"}
            sticky
            transparent
            relative
            center
        />
      </MKBox>
    {/* SECTION 1 */}
    {isDesktopOrLaptop ? (
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          //backgroundImage: `url(https://res.cloudinary.com/dbt1oxzwd/image/upload/v1686899974/Animated_Shape_gdp7a2.svg)`,
          //backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <div style={{display:"flex", justifyContent:"center",flexDirection:"column"}}>
              <MKTypography
                variant="h1"
                color="#1A1919"
                mt={-6}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                <span className="font-title">{contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Accueil").fields.Titre}</span>
              </MKTypography>
            </div>
            <MKTypography textAlign="center" px={6} mt={1}>
             <span className="font-paragraphe">
             {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Accueil").fields.Texte}
              </span>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>  
    ) : (
      <MKBox
      minHeight="50vh"
      width="100%"
      sx={{
        //backgroundImage: `url(https://res.cloudinary.com/dbt1oxzwd/image/upload/v1686899974/Animated_Shape_gdp7a2.svg)`,
        //backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
          <div style={{display:"flex", justifyContent:"center",flexDirection:"column",zIndex:10}}>
            <MKTypography
              variant="h1"
              color="#1A1919"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <span className="font-title">{contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Accueil").fields.Titre}</span>
            </MKTypography>
          </div>
          <div style={{zIndex:10}}>
          <MKTypography textAlign="center" px={6} mt={1}>
           <span className="font-paragraphe" >
           {contentData.find(item => item.fields.key === "Section 1 " + langContext && item.fields.Page === "Accueil").fields.Texte}
            </span>
          </MKTypography>
          </div>
        </Grid>
      </Container>
    </MKBox>  
    )}
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* STAT5ISTIQUES */}
        <Counters />
        {/* EN QUELQUES MOTS */}
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
                variant="gradient"
                color="info"
                icon="adjust"
                title={contentData.find(item => item.fields.key === "En quelques mots 1 " + langContext && item.fields.Page === "Accueil").fields.Titre}
                description={contentData.find(item => item.fields.key === "En quelques mots 1 " + langContext && item.fields.Page === "Accueil").fields.Texte}
                action={{
                  type: "internal",
                  route: "/impacts",
                  label: langContext === "Français" ? "En savoir plus" : "View more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
              variant="gradient"
                color="primary"
                icon="groups"
                title={contentData.find(item => item.fields.key === "En quelques mots 2 " + langContext && item.fields.Page === "Accueil").fields.Titre}
                description={contentData.find(item => item.fields.key === "En quelques mots 2 " + langContext && item.fields.Page === "Accueil").fields.Texte}
                action={{
                  type: "internal",
                  route: "/cohortes",
                  label: langContext === "Français" ? "En savoir plus" : "View more",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FilledInfoCard
              variant="gradient"
                color="warning"
                icon="apps"
                title={contentData.find(item => item.fields.key === "En quelques mots 3 " + langContext && item.fields.Page === "Accueil").fields.Titre}
                description={contentData.find(item => item.fields.key === "En quelques mots 3 " + langContext && item.fields.Page === "Accueil").fields.Texte}
                action={{
                  type: "internal",
                  route: "/technologies",
                  label: langContext === "Français" ? "En savoir plus" : "View more",
                }}
              />
            </Grid>
          </Grid>
        </Container>

{/* DEPISTAGE */}
        <Grid
            container
            item
            xs={12}
            lg={7}
            justifyContent="center"
            mx="auto"
            textAlign="center"
            pb={6}
            sx={{ mt: 16 }}
          >
            <MKTypography variant="h2" mb={3}>
              {langContext === "Français" ?
              <span className="font-title-black">
              {contentData.find(item => item.fields.key === "Dépistage " + langContext && item.fields.Page === "Accueil").fields.Titre}
              <span className="font-title-purp">{contentData.find(item => item.fields.key === "Dépistage " + langContext && item.fields.Page === "Accueil").fields["Sous texte"]}</span>
              </span>
              :
              <span className="font-title-black">
              <span className="font-title-purp">{contentData.find(item => item.fields.key === "Dépistage " + langContext && item.fields.Page === "Accueil").fields["Sous texte"]} </span>
              {contentData.find(item => item.fields.key === "Dépistage " + langContext && item.fields.Page === "Accueil").fields.Titre}
              </span>
              }
              <br />
              {/* <span className="fontKionaGold">niveau supérieur</span> */}
            </MKTypography>
            <MKTypography variant="body1" color="text">
              <span className="font-paragraphe">
              {contentData.find(item => item.fields.key === "Dépistage " + langContext && item.fields.Page === "Accueil").fields.Texte} 
              </span>
            </MKTypography>
            <MKButton onClick={()=>{window.location.href="https://www.gustaveroussy.fr/fr/cancer-poumon?gad=1&gclid=EAIaIQobChMIrfvak4vX_wIVewcGAB1FiwnHEAAYASAAEgLP2vD_BwE"}} style={{marginTop:"30px"}} variant="gradient" color="primary" sx={{ height: "100%" }}>
            {langContext === "Français" ? "Se faire dépister" : "Get tested"}
            </MKButton>
        </Grid>

        <BlogPostTwo/>

        <LogoAreaThree/>

        <ContactUsOne />


{/*         <MKBox component="section" position="relative" py={4}>
            <Container sx={{ maxWidth: "100% !important" }}>
              <Grid container item xs={11} sx={{ mx: "auto" }}>
                <MKBox variant="gradient" bgColor="rgb(241,242,245,0.1)" borderRadius="lg" width="100%" py={6}>
                  <Grid container>
                    <Grid item xs={12} md={6} xl={4} sx={{ position: "relative", px: 6 }}>
                      <MKBox
                        component="img"
                        src="https://img.freepik.com/photos-gratuite/sport-equipe-mains-air-joyeux-souriant-heureux-exercice-ensemble-mode-vie-amis-amour-relation-unite-gens-ventilateur-mains-gagnants_343629-260.jpg"
                        alt="image"
                        borderRadius="md"
                        maxWidth="300px"
                        width="100%"
                        position="relative"
                        mt={-12}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      xl={7}
                      sx={{
                        position: "relative",
                        px: { xs: 6, md: 2 },
                        mt: { xs: 3, md: 0 },
                        my: { xs: 0, md: "auto" },
                        color: ({ palette: { white } }) => white.main,
                      }}
                    >
                      <VolunteerActivismIcon fontSize="large" color="light" />

                      <MKTypography variant="h3" my={1} mb={3} color="light">
                        <span className="fontKThin">
                          En réservant une table via Tablaba, vous alliez{" "}
                          <span className="bigWordThin">plaisir</span> et{" "}
                          <span className="bigWordThin">solidarité</span>.
                        </span>
                      </MKTypography>
                      <MKTypography
                        variant="body1"
                        color="white"
                        fontWeight="light"
                        mb={2}
                        sx={{ fontSize: "1.125rem" }}
                      >
                        <span className="fontPara">
                          Pour chaque réservation payante effectuée via l&apos;interface Tablaba, 10
                          centimes minimum sont reversés à des œuvres caritatives.
                        </span>
                      </MKTypography>
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                </MKBox>
              </Grid>
            </Container>
        </MKBox> */}
        {/* <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              <span className="font-title-sec">Le projet Prevalung EU</span>
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
            In view of the world-wide epidemic of cancer, which is linked to environmental and life-style factors, there is an unmet medical need in breakthrough concepts to understand the causes of neoplasia.
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
            One possible factor contributing to oncogenesis and tumor progression that has been neglected is the microbiota.            </MKTypography>
            <MKTypography
              component="a"
              href="#"
              variant="body2"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              En savoir plus
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -4, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <AboutUsOption
                icon="mediation"
                content={
                  <>
                    It becomes harder for us to give others a hand.
                    <br />
                    We get our heart broken by people we love.
                  </>
                }
              />
              <AboutUsOption
                icon="settings_overscan"
                content={
                  <>
                    As we live, our hearts turn colder.
                    <br />
                    Cause pain is what we go through older.
                  </>
                }
              />
              <AboutUsOption
                icon="token"
                content={
                  <>
                    When we lose family over time.
                    <br />
                    What else could rust the heart Blackgold.
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
        </MKBox> */}

        {/* <Information /> */}

        {/* <DesignBlocks /> */}
        
        {/* <Pages /> */}
        
        {/* <Testimonials /> */}


      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
    ): null
    }
    </>
  );
}

export default Presentation;
