// ProductDetails.js
import { useState, useEffect,useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKPagination from "components/MKPagination";
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";
import routesEN from 'routesEN';

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";
import Steps from "pages/Blogs/SingleArticle/sections/Steps";
import OurEfforts from "pages/Blogs/SingleArticle/sections/OurEfforts";
import Features from "pages/Blogs/SingleArticle/sections/Features";
import Posts from "pages/Blogs/SingleArticle/sections/Posts";
import Support from "pages/Blogs/SingleArticle/sections/Support";
import {getArticles, getPages } from "../../../../src/hooks/airtable";
import { Context } from "../../../Context";
import ContactUsOne from "pages/presentation/sections/Contact";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
const ArticleDetails = () => {

 const { slug } = useParams();

 const [articles, setArticles] = useState([]);
 const [article, setArticle] = useState([]);
 const [articleInit, setArticleInit] = useState([]);
 const [isArticle, setIsArticle] = useState();
 const {langContext, setLangContext} = useContext(Context);
 const [articleTrad, setArticleTrad] = useState([]);
 const [langArticle, setLangArticle] = useState();
 const [isFetch, setIsFetch] = useState(false);
 const checkSlug = (slug,articles) => {
    for(let i = 0; i < articles.length; i++){
        if(articles[i].id === slug){
            setIsArticle(true);
            setArticle(articles[i]);
            setArticleInit(articles[i]);
            setLangArticle(articles[i].fields.Langue);
            for(let j = 0; j < articles.length; j++){
                if(articles[j].fields.Image[0].url === articles[i].fields.Image[0].url && articles[j].fields.Langue !== articles[i].fields.Langue){
                    setArticleTrad(articles[j]);
                    break;
                }
            }
            break;
        }
        else{
            setIsArticle(false);
        }
    }
    }
 const getOtherLanguage = () => {
    for(let i = 0; i < articles.length; i++){
        if(articles[i].fields.Image[0].url === article.fields.Image[0].url){
            setArticleTrad(articles[i]);
            break;
        }
    }
    }
 useEffect(() => {
    async function fetchData() {
      const dataArticles = await getArticles();
      setArticles(dataArticles);
      setIsFetch(true);
      checkSlug(slug,dataArticles);
      //getOtherLanguage();
    }
    fetchData();
    
  }, [slug]);

  useEffect(() => {
    
    if(langContext !== langArticle){
        setIsFetch(false);
        setArticle(articleTrad);
        setLangArticle(langContext);
        setArticleTrad(articleInit);
        setArticleInit(articleTrad);
        setIsFetch(true);
    }
  }, [langContext]);

 return (
    <>
      <MKBox shadow="sm" py={0.25} sx={{position: "sticky", top: 0, zIndex: 10,backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",}}>
        <DefaultNavbar
          routes={langContext =="Français" ? routes : routesEN}
          action={{
            type: "external",
            route: "https://www.creative-tim.com/product/material-kit-pro-react",
            label: "buy now",
            color: "info",
          }}
          brand={"Prevalung"}
            sticky
            transparent
            relative
            center
        />
      </MKBox>
      {isFetch ? (
      <>
      <MKBox
        minHeight="55vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.7),
              rgba(gradients.dark.state, 0.7)
            )}, url(${isArticle ? article.fields.Image[0].url : "https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687774843/Untitled_9_kemkmp.png"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h2"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              <span className='titre-article'>{isArticle ? article.fields.Titre : "Article not found"}</span>
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              
            </MKTypography>

            {/* <MKButton style={{marginTop:"5px"}} variant="gradient" color="primary" sx={{ width:"200px" }} onClick={()=>{executeScroll()}}>
            {langContext === "Français" ? "Rejoindre" : "Join"}
            </MKButton> */}
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MKBox component="section" py={1} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                {isArticle ? article.fields.Extrait : null}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <MKBox component="section" py={1} mt={6}>
          <Container>
            <Grid container spacing={3} item xs={12} lg={8} mx="auto">
              <MKTypography variant="body2" style={{textAlign:"justify"}}>
                <span>
                {isArticle ? article.fields.Corps : null}
                </span>
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <div>
          <ContactUsOne/>
        </div>
        {/* <Steps />
        <OurEfforts />
        <Features />
        <Posts />
        <Support /> */}
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      </>): null
      }
    </>
 );
};

export default ArticleDetails;