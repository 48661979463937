// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import coinbase from "assets/images/logos/medium-logos/logo-coinbase.svg";
import nasa from "assets/images/logos/medium-logos/logo-nasa.svg";
import netflix from "assets/images/logos/medium-logos/logo-netflix.svg";
import pinterest from "assets/images/logos/medium-logos/logo-pinterest.svg";
import spotify from "assets/images/logos/medium-logos/logo-spotify.svg";
import vodafone from "assets/images/logos/medium-logos/logo-vodafone.svg";
import { Context } from "../../../Context";
import { useEffect, useRef, useState,useContext } from "react";

function LogoAreaThree() {
  const {langContext, setLangContext} = useContext(Context);

  return (
    <MKBox py={6}>
      <Container>
        <Grid
          container
          item
          justifyContent="center"
          xs={8}
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h3" mb={1}>
          <span className="font-title-black">{langContext === "Français" ? "Partenaires" : "Partners"}</span>
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 , justifyContent:"center"}}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107895/Logo-gustave-roussy-removebg-preview_gto88s.png"} alt="igr" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687116402/uclh-website-removebg-preview_aahcph.png"} alt="NHS" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107895/2751-institut-national-sante-et-recherche-medicale-inserm-removebg-preview_vkqohl.png"} alt="inserm" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107895/Bio-Me-Header-Logo-removebg-preview_lmjhoq.png"} alt="bio-me" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2} style={{marginTop:"5px"}}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107896/logo-universita-degli-studi-di-torino-removebg-preview_uei3k7.png"} alt="UNITO" width="100%" opacity={0.9} />
          </Grid>
          
          
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 , justifyContent:"center"}}>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107895/logo-erasmusmc-rgb-wit-nl-scaled-removebg-preview_sxpmkj.png"} alt="erasmus-mc" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107895/1280px-Assistance_publique_-_Ho%CC%82pitaux_de_Marseille_logo_2020.svg-removebg-preview_1_asz3lq.png"} alt="ap-hm" width="100%" opacity={0.9} />
          </Grid>
          
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107896/olink-logotype-black-removebg-preview_s17pvd.png"} alt="o-link" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107896/seerave-logo-removebg-preview_xdwthk.png"} alt="seerave-foundation" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107895/HSTJref_RVB-removebg-preview_d6yk1z.png"} alt="fhpsj" width="100%" opacity={0.9} />
          </Grid>
          
        </Grid>
        <Grid container spacing={3} sx={{ mt: 3 , justifyContent:"center"}}>
        <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107896/te%CC%81le%CC%81chargement-removebg-preview_utjpfl.png"} alt="ku-leuven" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687116206/Gustave-removebg-preview_copie_kcq3h0.png"} alt="grt" width="100%" opacity={0.9} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={"https://res.cloudinary.com/dbt1oxzwd/image/upload/v1687107896/logo-patients-en-re%CC%81seau-gris-300x106-removebg-preview_mt5iin.png"} alt="patients" width="100%" opacity={0.9} />
          </Grid>
          
        </Grid>
      </Container>
    </MKBox>
  );
}

export default LogoAreaThree;