/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import { useEffect, useRef, useState,useContext } from "react";

// Material Kit 2 PRO React examples   
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { Context } from "../../../Context";
import {getArticles, getPages } from "../../../../src/hooks/airtable";

function Counters() {
  const {langContext, setLangContext} = useContext(Context);
  const [contentData, setContentData] = useState([]);
  const [isFetch, setIsFetch] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getPages();
      setContentData(data);
      setIsFetch(true)
    /*   console.log(data)
      console.log(contentData.find(item => item.fields.key === "Section 1 " + langContext).fields.Titre); */
    }
    fetchData();

  }, []);
  return (
    <>
      {isFetch && (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={parseInt(contentData.find(item => item.fields.key === "Nombre Patients " + langContext && item.fields.Page === "Accueil").fields.Titre)}
              suffix="+"
              title={contentData.find(item => item.fields.key === "Nombre Patients " + langContext && item.fields.Page === "Accueil").fields.Texte}
              description={contentData.find(item => item.fields.key === "Nombre Patients " + langContext && item.fields.Page === "Accueil").fields["Sous texte"]}
            />
          </Grid>
          <Grid item xs={12} md={3} display="flex">
            <DefaultCounterCard
              count={parseInt(contentData.find(item => item.fields.key === "Budget " + langContext && item.fields.Page === "Accueil").fields.Titre)}
              suffix=" M€"
              title={contentData.find(item => item.fields.key === "Budget " + langContext && item.fields.Page === "Accueil").fields.Texte}
              description={contentData.find(item => item.fields.key === "Budget " + langContext && item.fields.Page === "Accueil").fields["Sous texte"]}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={parseInt(contentData.find(item => item.fields.key === "Partenaires " + langContext && item.fields.Page === "Accueil").fields.Titre)}
              suffix=""
              title={contentData.find(item => item.fields.key === "Partenaires " + langContext && item.fields.Page === "Accueil").fields.Texte}
              description={contentData.find(item => item.fields.key === "Partenaires " + langContext && item.fields.Page === "Accueil").fields["Sous texte"]}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <DefaultCounterCard
              count={parseInt(contentData.find(item => item.fields.key === "Durée Programme " + langContext && item.fields.Page === "Accueil").fields.Titre)}
              suffix={langContext == "Français" ? " mois" : " months"}
              title={contentData.find(item => item.fields.key === "Durée Programme " + langContext && item.fields.Page === "Accueil").fields.Texte}
              description={contentData.find(item => item.fields.key === "Durée Programme " + langContext && item.fields.Page === "Accueil").fields["Sous texte"]}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>

      )}
    

    </>
  );
}

export default Counters;
